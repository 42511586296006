import { Button, Card, CardBody, Chip, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Tooltip } from "@nextui-org/react";
import ToolTipFix from "../extensions/TooltipFix";
import UserChip from "../small/UserChip";
import { Key } from "react";
import { useLetsTalk } from "../../hooks/useLetsTalk";
import VSCNCoin from "../small/VSCNCoin";


export type MessageModel = {
    id: number;
    user: { wallet: string };
    createdAt?: Date;
    message: string;
    data: {
        amount?: number;
        side?: number;
    }
}

declare var confirm: any;

export default function MessageComponent({ message, right = false, admin = false }: { message: MessageModel, right?: boolean, admin?: boolean }) {
    // const { user } = useSelector((state: any) => state.userData);

    const { doPost } = useLetsTalk();

    async function adminAction(action: Key) {
        switch (action) {
            case "delete":
                if (confirm(`Are you sure you want to delete this message? Msg: ${message.message}`)) {
                    let x = await doPost("admin/delete-chat-message", { msg: message });
                    alert(JSON.stringify(x));
                }
                return;
            case "shadow": {
                let sec = Number.parseInt(prompt(`Seconds to shadow ban (0 for perma) "${JSON.stringify(message?.user?.wallet)}" user? Msg: ${message.message}`) || '');
                if (!isNaN(sec)) {
                    let reason = prompt(`Reason for shadow ban "${JSON.stringify(message?.user?.wallet)}" user? Msg: ${message.message}`);
                    let x = await doPost("admin/ban-user", {
                        userWallet: message.user.wallet,
                        reason: reason,
                        type: "CHAT_SHADOW", //"CHAT"
                        seconds: sec,
                        permanent: sec === 0,
                        msg: message
                    });
                    alert(JSON.stringify(x));

                }
                return;
            }
            case "ban": {
                let sec = Number.parseInt(prompt(`Seconds to ban (0 for perma) "${JSON.stringify(message?.user?.wallet)}" user? Msg: ${message.message}`) || '');
                if (!isNaN(sec)) {
                    let reason = prompt(`Reason for ban "${JSON.stringify(message?.user?.wallet)}" user? Msg: ${message.message}`);
                    let x = await doPost("admin/ban-user", {
                        userWallet: message.user.wallet,
                        reason: reason,
                        type: "CHAT",
                        seconds: sec,
                        permanent: sec === 0,
                        msg: message

                    });
                    alert(JSON.stringify(x));

                }
                return;
            }
        }
        return;

    }


    //    max-w-full
    return (
        <Card className={`
        border-none bg-background/60 dark:bg-default-100/50 w-auto max-w-full
        clear-both m-1  max-w-[90%] ${right ? 'float-right self-end' : 'float-left self-start'}`}
            isBlurred
        >
            <CardBody className={` text-md p-2  scale-in-center`}>
                <span className={`text-sm  text-wrap break-words`}>
                    <ToolTipFix showArrow={false}
                        // triggerClassName={"inline"}
                        triggerClassName={right ? 'inline float-right ml-1' : 'inline float-left mr-1'}
                        content={
                            <div className="block m-1">
                                {message?.data?.amount && <div className="font-md">
                                    {message.data.amount.toFixed(2)}
                                    <VSCNCoin />
                                </div>}
                                <div className="text-tiny">{message.user?.wallet}</div>
                                {message.createdAt && <div className="text-md">{(new Date(message.createdAt)).toLocaleString()}</div>}
                            </div>
                        }>


                        <UserChip wallet={message.user?.wallet} size="sm"
                        // className={right ? 'float-right ml-1' : 'float-left mr-1'} 
                        />

                        {admin && <Dropdown>
                            <DropdownTrigger className="inline">
                                ○
                            </DropdownTrigger>
                            <DropdownMenu onAction={adminAction}>
                                <DropdownItem key="delete">Delete</DropdownItem>
                                <DropdownItem key="shadow">Shadow ban</DropdownItem>
                                <DropdownItem key="ban">Ban</DropdownItem>
                            </DropdownMenu >
                        </Dropdown>}
                    </ToolTipFix>
                    &nbsp;
                    {message.message}
                </span>
            </CardBody>
        </Card>


    )
}