import { combineReducers } from 'redux';
import walletReducer from './walletReducer';
import betaReducer from './betaReducer';
import battlesReducer from './battlesReducer';
import lotteriesReducer from './lotteriesReducer';
import maintenanceReducer from './maintenanceReducer';
import duelsReducer from './duelsReducer';
import userReducer from './userReducer';
import chatReducer from './chatReducer';

const rootReducer = combineReducers({
  walletBalance: walletReducer,
  userData: userReducer,
  betaData: betaReducer,
  battlesData: battlesReducer,
  lotteriesData: lotteriesReducer,
  duelsData: duelsReducer,
  maintenanceData: maintenanceReducer,
  chatData: chatReducer
});


export default rootReducer;