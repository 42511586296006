import { Button, Card, CardBody, CardHeader, Chip, Input, Link, ScrollShadow, Spinner } from "@nextui-org/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faMessage, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useLetsTalk } from "../../hooks/useLetsTalk";
import { addMessageAction, prependMessagesAction, resetNewMessagesCounterAction, setMessagesAction } from "../../store/actions/reducerActions";
import { toast } from "react-toastify";
import InfoTooltip from "../info-tooltip/InfoTooltip";
import Message from "./Message";


const CHAT_SETTING_KEY = "VSCNChatExpanded";

export default function ChatBox() {
    const [expanded, setExpanded] = useState(false);
    const [input, setInput] = useState('');
    // const [sendDisabled, setSendDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadError, setLoadError] = useState<string | undefined>(undefined);
    const [sending, setSending] = useState(false);

    const { messages, newMessagesCounter } = useSelector((state: any) => state.chatData);
    const { user } = useSelector((state: any) => state.userData);

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        setIsAdmin(user?.admin && (user?.adminRoles?.includes("ALL") || user?.adminRoles?.includes("CHAT")));
    }, [user]);

    const { doGet, doPost } = useLetsTalk();
    const dispatch = useDispatch();

    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

    const messagesEndRef = useRef(null);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        setIsScrolledToBottom(bottom);
    };

    useEffect(() => {
        if (isScrolledToBottom) {
            (messagesEndRef.current as any)?.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, isScrolledToBottom]);

    useEffect(() => {
        if (expanded) {
            dispatch(resetNewMessagesCounterAction());
        }
    }, [newMessagesCounter, expanded]);


    const loadMessages = (more = false) => {
        setLoading(true);
        setLoadError('');

        let lastIdQ = '';
        if (more) {
            let lastId = messages[0]?.id;
            lastIdQ = `?lastId=${lastId}`;
        }
        doGet(`chat/get-messages${lastIdQ}`).then((ret) => {
            if (ret.ok) {
                if (more) {
                    dispatch(prependMessagesAction(ret.messages));
                } else {
                    dispatch(setMessagesAction(ret.messages));
                }
                setLoadError(undefined);
            } else {
                setLoadError(ret?.error || ret?.toString() || "Error loading messages.");
            }

            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setLoadError('Failed to load messages.');
            setLoading(false);

        });
    }

    useEffect(() => {
        loadMessages();
        if (document.documentElement.clientWidth > 640) {
            let expanded = (localStorage.getItem(CHAT_SETTING_KEY) !== "false");
            setExpanded(expanded);
        }
    }, []);

    // useEffect(() => {
    //     // Step 3: Scroll to the bottom of the messages container
    //     (messagesEndRef.current as any)?.scrollIntoView({ behavior: "smooth" });
    // }, [messages]);

    const sendMessage = async () => {
        if (sending) return false;
        if (!input || input.length < 3) return false;
        setSending(true);

        let msg = input;
        setInput('');

        doPost(`chat/send-message`, { message: input }).then((data) => {
            // console.log("Message sent", data);
            setSending(false);
            if (!data.ok && data.error) {
                toast.error(data.error);
            }
        }).catch((error) => {
            console.error(error);
            toast.error("Failed to send message.");
            setSending(false);
        }).finally(() => {
            setSending(false);

        });
    }

    return (
        <Card isBlurred
            className={`fixed right-0 md:right-1 z-10 bottom-0 text-center m-auto w-auto max-w-xl border-none 
              bg-background/50 dark:bg-default-100/70 p-0 rounded-b-none max-w-full 
              ${expanded ? ' min-w-full sm:min-w-80 h-[85vh] sm:max-h-[600px] sm:max-w-80' : 'min-w-24 sm:min-w-36'}`}
            // style={{
            //     maxHeight: expanded ? '85vh' : 'auto',
            // }}
            shadow="sm">
            <CardHeader
                onClick={() => {
                    if (!expanded) {
                        dispatch(resetNewMessagesCounterAction());
                    }
                    setExpanded(!expanded);
                    localStorage.setItem(CHAT_SETTING_KEY, (!expanded).toString());
                    setIsScrolledToBottom(true);
                    requestAnimationFrame(() => {
                        (messagesEndRef.current as any)?.scrollIntoView({ behavior: "smooth" });
                    });

                }}
                className="flex flex-row items-center text-lg text-left block align-middle bg-default-100/50 cursor-pointer"
            >
                Chat
                {newMessagesCounter > 0 && <Chip startContent={<FontAwesomeIcon icon={faMessage} />}
                    variant="light"
                    className="mx-2"
                    size="lg"
                    color="danger"
                >{newMessagesCounter}</Chip>}


                <FontAwesomeIcon icon={expanded ? faAngleDown : faAngleUp} className="mt-2 float-right " />

                {expanded && <InfoTooltip
                    // classNames={{ trigger: "float-right relative mt-2 mr-4" }} 
                    classNames={{ trigger: "float-right relative mt-2 mr-4" }}

                    infoHeader="Chat rules" infoText={<>
                        <ul className="list-disc ml-4">
                            <li>Do not mislead/scam.</li>
                            <li>Do not beg for crypto.</li>
                            <li>Do not spam.</li>
                            <li>Do not share or advertise other websites, coins and etc.</li>
                        </ul>
                        <br />
                        <p>You might get banned for any specified or unspecified reason for an indefinite amount of time.</p>
                    </>} />}

            </CardHeader>
            <CardBody className={`flex flex-col max-h-sm  p-0 ${expanded ? 'visible' : 'hidden'}`}>
                <ScrollShadow className="flex-grow text-center h-full w-full px-1 overflow-x-hidden overflow-y-auto"
                    onScroll={handleScroll}>
                    {loadError && <div className="text-center font-bold text-red-500">
                        {loadError}
                        <br />
                        <Button color="primary" onClick={() => loadMessages()}>Retry</Button>
                    </div>}

                    {loading && <Spinner className="m-auto clear-both" size="lg" />}

                    {messages && messages.length > 1 && messages[0]?.id > 1 && <Button variant="light" color="primary" onClick={() => loadMessages(true)} className="block text-centerclear-both m-auto mb-2">Load more messages</Button>}

                    {messages?.map((message: any) => (
                        // <ChatMessageComponent key={message.id} message={message} />
                        <Message key={message.id} message={message} right={message.user.wallet == user?.wallet}
                            admin={isAdmin} />
                    ))}

                    <div className="clear-both" ref={messagesEndRef} ></div>
                </ScrollShadow>

                <div className="m-2">
                    {user ?

                        <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
                            <Input
                                className="m2 w-full"
                                type="text"
                                // label="Your message"
                                placeholder="Your message"
                                labelPlacement="outside"
                                value={input}
                                onValueChange={setInput}
                                maxLength={128}
                                endContent={
                                    sending ?
                                        <Spinner size="sm" />
                                        : <FontAwesomeIcon onClick={sendMessage} icon={faPaperPlane} className={`cursor-pointer ${(!input || input?.length < 3) ? 'opacity-50' : 'opacity-100'}`} />
                                }
                            />

                        </form> :
                        <Link href={"/login"} color="foreground" className="text-center m-auto p-2">You need to sign in to chat.</Link>
                    }
                </div>

            </CardBody>
        </Card>
    )

}
