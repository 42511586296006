import { TOKEN_PROGRAM_ID, TokenAccountNotFoundError, TokenInvalidAccountOwnerError, createAssociatedTokenAccountInstruction, createTransferInstruction, getAccount, getAssociatedTokenAddress, getAssociatedTokenAddressSync, getOrCreateAssociatedTokenAccount, } from '@solana/spl-token';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { BlockheightBasedTransactionConfirmationStrategy, ComputeBudgetProgram, PublicKey, SystemProgram, Transaction, sendAndConfirmTransaction } from '@solana/web3.js';

import { useState } from 'react';

import { toast } from 'react-toastify';
import useWalletBalance from './useWalletBalance';
import { useSelector } from 'react-redux';
import anal from '../services/anal';
import bs58 from 'bs58';
import { useLetsTalk } from './useLetsTalk';


//REAL: 4GCJwmLfuk1F6s5Z6TxeLoBYQocaDh9yNLFxPokDHV7r
//DEV: 9k2eYN1VzaQhnZBx1F49rhSKwCeSomF3u12PjAFK1ikL

const VSCN_MINT = new PublicKey(process.env.REACT_APP_VSCN_MINT!);
const VSCN_DECIMALS = Number.parseInt(process.env.REACT_APP_VSCN_DECIMALS!);

const useDoTransaction = () => {

    const { connection } = useConnection();
    const { publicKey, sendTransaction, signTransaction } = useWallet();
    const { refetchBalance } = useWalletBalance();

    const { currentFee, betLimit, lamportsRequiredToBet } = useSelector((state: any) => state.betaData);

    const maintenance = useSelector((state: any) => state.maintenanceData);
    // console.log("maintenance", maintenance);
    const { doPost } = useLetsTalk();



    const notifyServer = async ({ url, wallet, transData, signature, confirmation, successMessage, retries = 0 }) => {
        const resolveWithSomeData: Promise<{ ok: boolean, data?: any, error?: string }> = new Promise(async (resolve, reject) => {

            const MAX_RETRIES = 5;
            for (let retries = 1; retries <= MAX_RETRIES; retries++) {
                try {
                    if (retries > 1) {
                        // Wait before retrying
                        await new Promise(r => setTimeout(r, 5000 * retries));
                    }

                    let ret = await doPost(url, { wallet, transData, signature, confirmation });
                    if (ret?.ok) {
                        refetchBalance();
                        anal.event({ category: "transaction", action: "doTransaction_notify_success" });
                        // return ret; // Resolve the promise with the result
                        return resolve(ret);
                    } else {
                        console.error("DO TRANSACTION NOTIFY SERVER ERROR", retries, ret);
                        anal.event({ category: "transaction", action: "doTransaction_notify_error" });
                        if (retries === MAX_RETRIES) throw new Error(ret.error ? ret.error : JSON.stringify(ret)); // Throw error on last retry
                    }
                } catch (e) {
                    console.error("DO TRANSACTION NOTIFY SERVER ERROR CAUGHT", e);
                    if (retries >= MAX_RETRIES) {
                        return reject({ error: e });
                        // throw e; 
                    }
                }
            }



        });

        toast.promise(
            resolveWithSomeData,
            {
                pending: "Server is processing the transaction...",
                success: successMessage ? successMessage : "Bet confirmed!",
                error: {
                    render({ data }: any) {
                        console.error("DO TRANSACTION ERROR (while notifying server) (toast)", data);
                        return `Error while notifying server! ${data?.error ? data?.error.toString() : data?.toString()}`
                    }
                }
            }
        )

        return resolveWithSomeData;
    };

    const onConfirmRefresh = function (event) {
        event.preventDefault();
        return event.returnValue = "Are you sure you want to leave the page? Transaction is still pending.";
    }

    type TransactionData = {
        amount: number;
        toWallet: string;
        toTokenWallet?: string;
        // battleId?: number;
        // side?: number;
        [key: string]: any; // This allows any other properties
    };

    type TransactionOptions = {
        url: string;
        transData: TransactionData;
        successMessage: string;
        checkBeforeTransaction?: () => Promise<boolean>;
    };

    async function doTransaction({ url, transData, successMessage, checkBeforeTransaction = async () => true }: TransactionOptions): Promise<{ ok: boolean, data?: any, error?: any }> {
        /*
        transData: {
            amount: number,
            toWallet: string,
            toTokenWallet: string?,
            ...whatever
            battleId: number,
            side: number,
        }
        */
        // console.log("doTransaction called", url, transData);
        anal.event({ category: "transaction", action: "doTransaction" });

        if (maintenance && maintenance?.starts < Date.now() && maintenance?.enabled) {
            toast.error("Transactions disabled because of the maintenance. Please wait until the maintenance is over.");
            return { ok: false, error: "Maintenance" };
        }

        if (!publicKey) {
            console.error("no publickey!");
            toast.error("Error! Wallet not connected!");
            // return false;
            return { ok: false, error: "Wallet not connected" };
        }

        try {
            if (isNaN(transData.amount)) {
                throw new Error("Invalid amount");
            }

            window.addEventListener("beforeunload", onConfirmRefresh, { capture: true });

            let sendToWallet = new PublicKey(transData.toWallet);

            // const lamports = await connection.getMinimumBalanceForRentExemption(0);

            // let vscnBalance = 0;//BigInt(0);

            let clientTokenAcc = getAssociatedTokenAddressSync(new PublicKey(VSCN_MINT), publicKey);

            // const infoToken = await getAccount(connection, clientTokenAcc);
            // const balanceAountReal = infoToken.amount;
            // vscnBalance = Number(balanceAountReal / BigInt(10 ** VSCN_DECIMALS));
            let userVSCNamountReal = BigInt(0);
            let vscnBalance = 0;
            let decimals = VSCN_DECIMALS;
            let infoToken;
            try {
                infoToken = await getAccount(connection, clientTokenAcc);
            } catch (e: any) {
                if (e instanceof TokenAccountNotFoundError) {
                    console.log("Token account not found, acting as balance is 0. ", e);
                } else {
                    console.error(" Error getting token account! ", e);
                    // return null;
                }
            }

            if (infoToken) {
                userVSCNamountReal = infoToken?.amount || 0;
                vscnBalance = Number(userVSCNamountReal / BigInt(10 ** decimals));
            } else {
                console.log("[doTransaction getBalance] No token info found (balance probably 0)", clientTokenAcc.toString());
            }

            // vscnBalance = Number(userVSCNamountReal / BigInt(10 ** decimals));
            // console.log('[doTransaction getBalance] Amount, real: ', vscnBalance, userVSCNamountReal);


            if (!vscnBalance || vscnBalance < transData.amount) {
                toast.error("Error! Insuficient VSCN balance!");
                return { ok: false, error: "Insuficient VSCN balance" };
            }

            // console.log("Creatting transfer", clientTokenAcc.toString(), VSCN_MINT.toString(), sendToWallet.toString(), publicKey?.toString(), transData.amount);
            //sendToTokenWallet?.toString()

            transData.tokenWallet = clientTokenAcc.toString();
            transData.wallet = publicKey?.toString();

            let amountReal = BigInt(Math.round(transData.amount * Math.pow(10, VSCN_DECIMALS)));
            // console.log("Sending amount ", transData.amount, amountReal);
            // console.log("lamports, lamportsRequiredToBet", lamports, lamportsRequiredToBet);

            const betFeeLamports = lamportsRequiredToBet;// lamports > lamportsRequiredToBet ? lamports : lamportsRequiredToBet;

            const transaction = new Transaction();
            transaction.add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: sendToWallet,
                    lamports: betFeeLamports//lamportsRequiredToBet, // lamports * 2,
                })
            );
            // console.log("Transaction adding insturction SystemProgram.transfer", {
            //     fromPubkey: publicKey.toString(),
            //     toPubkey: sendToWallet.toString(),
            //     lamports: betFeeLamports//lamportsRequiredToBet, // lamports * 2,
            // });

            const toTokenAccount = getAssociatedTokenAddressSync(VSCN_MINT, sendToWallet);

            transData.toTokenWallet = toTokenAccount.toString();

            let account;
            try {
                account = await getAccount(connection, toTokenAccount); // associatedToken);
                // console.log("Account found", account);
            } catch (error: unknown) {
                // TokenAccountNotFoundError can be possible if the associated address has already received some lamports,
                // becoming a system account. Assuming program derived addressing is safe, this is the only case for the
                // TokenInvalidAccountOwnerError in this code path.
                if (error instanceof TokenAccountNotFoundError || error instanceof TokenInvalidAccountOwnerError) {
                    // As this isn't atomic, it's possible others can create associated accounts meanwhile.
                    // console.log("Account not found, adding createAssociatedTokenAccountInstruction instruction");
                    try {
                        transaction.add(
                            createAssociatedTokenAccountInstruction(
                                publicKey,
                                toTokenAccount, //associatedToken,
                                sendToWallet,
                                VSCN_MINT,
                                TOKEN_PROGRAM_ID,
                            )
                        );
                        // console.log("Transaction adding insturction transaction.add( createAssociatedTokenAccountInstruction", 
                        //     publicKey.toString(),
                        //     toTokenAccount.toString(), //associatedToken,
                        //     sendToWallet.toString(),
                        //     VSCN_MINT.toString(),
                        //     TOKEN_PROGRAM_ID,
                        // );

                    } catch (error: unknown) {
                        // Ignore all errors; for now there is no API-compatible way to selectively ignore the expected
                        // instruction error if the associated account exists already.
                        //niekad neturetu mest
                        console.error("Error while adding createAssociatedTokenAccountInstruction ", error);
                    }
                } else {
                    throw error;

                }
            }

            //geras
            transaction.add(
                createTransferInstruction(
                    clientTokenAcc,
                    toTokenAccount, //sendToTokenWallet || account?.address,
                    publicKey,
                    amountReal,
                    [],
                    TOKEN_PROGRAM_ID
                )
            );

            console.log("Transaction adding insturction createTransferInstruction",
                clientTokenAcc.toString(),
                toTokenAccount.toString(), //sendToTokenWallet || account?.address,
                publicKey.toString(),
                amountReal,
                [publicKey],
                TOKEN_PROGRAM_ID
            );

            // console.log("Transaction created", transaction);


            let lastestBlockhashAndContext = await connection.getLatestBlockhashAndContext("confirmed");

            let blockhash = lastestBlockhashAndContext.value.blockhash;
            let lastValidBlockHeight = lastestBlockhashAndContext.value.lastValidBlockHeight + 150;

            transaction.recentBlockhash = blockhash;
            transaction.lastValidBlockHeight = lastValidBlockHeight;

            transaction.feePayer = publicKey;

            // console.log("transaction.feePayer", publicKey.toString());

            const PRIORITY_FEE_IX = ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 300000 });
            transaction.add(PRIORITY_FEE_IX);

            // console.log("Transaction ready", transaction);


            if (!signTransaction) throw new Error('Wallet does not support transaction signing!');

            let signedTx = await signTransaction(transaction);
            if (!signedTx.signature) throw new Error('Transaction not signed!');
            const sig = bs58.encode(signedTx.signature);
            // console.log(`Transaction signed: ${sig}`);

            if (!signedTx.verifySignatures()) throw new Error(`Transaction signature invalid! ${sig}`);
            // console.log(`Transaction signature valid! ${sig}`);


            if (checkBeforeTransaction && typeof checkBeforeTransaction === 'function') {
                let checkResult = await checkBeforeTransaction();
                if (!checkResult) {
                    console.error("checkBeforeTransaction failed");
                    // return false;
                    return { ok: false, error: "A check before transaction failed." };
                } else {
                    // console.log("checkBeforeTransaction passed");
                }
            } else {
                // console.log("Not calling checkBeforeTransaction, because it is not a function or not provided", checkBeforeTransaction);
            }

            const rawTransaction = signedTx.serialize();
            // console.log("Sending transaction", rawTransaction);

            try {
                let sendRawTransactionRes = await connection.sendRawTransaction(rawTransaction, { minContextSlot: lastestBlockhashAndContext.context.slot });
            } catch (e) {
                console.error("sendRawTransaction caught error", e);
                console.error("sendRawTransaction caught logs", (e as any)?.getLogs?.());

                throw e;
            }

            // console.log("confirming transaction", sendRawTransactionRes);
            let confirmation = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature: sig });
            // console.log("confirmation", confirmation);

            window.removeEventListener("beforeunload", onConfirmRefresh, { capture: true });

            anal.event({ category: "transaction", action: "doTransaction_success" });

            // console.log("Notifying server about the bet", { wallet: publicKey, transData, sig, confirmation });
            let notifyRes = await notifyServer({ url, wallet: publicKey, transData, signature: sig, confirmation, successMessage });;

            return notifyRes; //{ ok: true, data: notifyRes };

        } catch (err: any) {
            console.error('Transaction error', typeof err, err);
            toast.error(`Error while doing transaction! ${err?.error ? err?.error?.toString() : err?.toString()}`);

            let logs = err?.getLogs?.();
            console.error("Transaction logs", logs);

            anal.event({ category: "transaction", action: "doTransaction_failed" });


            window.removeEventListener("beforeunload", onConfirmRefresh, { capture: true });

            return { ok: false, error: err };
            // setState(`Transaction error ${error}`);
        }
        // }, [publicKey, sendTransaction, connection]);
    };


    return { doTransaction };
};

export default useDoTransaction;