import { useCallback, useMemo, useState } from "react";
import { Button, Card, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { WalletBalance } from "../components/small/WalletBalance";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import anal from "../services/anal";
import useWalletBalance from "./useWalletBalance";


const useBetModal = ({ onCloseCallback, onSuccessCallback, modalData: _modalData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [betData, setModalData] = useState(_modalData);

    const [betAmount, setBetAmount] = useState(0);
    const [betMessage, setBetMessage] = useState("");

    const { currentFee, betLimit, lamportsRequiredToBet } = useSelector((state: any) => state.betaData);

    const { refetchBalance } = useWalletBalance();


    // backdropo clickas sita iskviecia
    // ir visi kiti sita kviecia :>
    const closeModal = useCallback(() => {
        if (!isLoading) {
            setIsLoading(false);
            setBetAmount(0);
            setBetMessage("");
            onCloseCallback();
            setIsOpen(false);
        }
    }, [isLoading])

    const openModal = (betData) => {
        // console.log("BET DATA", betData);
        // setModalData((prev) => ({ ...prev, ...modData }));
        refetchBalance();
        setModalData(betData);
        setIsOpen(true);
        anal.event({ category: "battle", action: "bet_modal_open", label: betData?.name, value: betData?.battleId });
    }

    const Component = useMemo(() => {
        return <Modal
            isOpen={isOpen}
            onOpenChange={closeModal}
            backdrop="blur"
            isDismissable={false}
            className="dark bg-default-100/50" classNames={{ closeButton: "z-10" }}
            scrollBehavior="outside"
        >
            <ModalContent>
                <Card className=" bg-default-100/50">
                    <ModalHeader className="flex flex-col gap-1">
                        <h2 className="text-center text-2xl">Betting on <b>{betData?.name}</b></h2>
                    </ModalHeader>
                    <ModalBody>
                        <div className="aspect-square overflow-hidden max-w-40 max-h-40 m-auto flex justify-center items-center" style={{
                           borderRadius: "50%", objectFit: 'contain', 
                            WebkitFilter: "drop-shadow(1px 0 0 white) drop-shadow(0 1px 0 white) drop-shadow(-1px 0 0 white) drop-shadow(0 -1px 0 white)"

                            //bg-center bg-contain bg-no-repeat
                            // backgroundImage: `url("${img2}")`,

                        }}>
                            <img loading="eager" className="max-w-full max-h-full w-full h-full" style={{ zIndex: 3, objectFit: 'contain' }} src={betData?.img} alt={betData?.name} />
                        </div>
                        {/* <div className="max-w-40 max-h-40 m-auto">
                            <img src={betData?.img} alt={betData?.name} className="h-auto w-auto" />
                        </div> */}

                        <div className="mt-8 text-center w-full flex flex-col justify-center justify-items-center items-center">
                            <Input
                                label="Message (optional)"
                                type="text"
                                className="max-w-xs"
                                maxLength={128}
                                onChange={(e) => { setBetMessage?.(e.target.value) }}
                                value={betMessage}
                            />
                            <br />

                            <h3 className=" text-small">Enter your bet</h3>
                            <Input
                                label="$VSCN amount"
                                type="number"
                                className="max-w-xs"
                                value={(betAmount > 0) ? betAmount.toString() : ''}
                                onChange={(e) => {
                                    setBetAmount(Number.parseFloat(e.target.value))
                                }}
                            />
                        </div>
                        <div className="text-center text-sm">
                            <WalletBalance />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="light" isDisabled={isLoading} onPress={() => {
                            closeModal();
                        }}>
                            Cancel
                        </Button>
                        <Button color="primary" isDisabled={isLoading || !betAmount || betAmount <= 0} isLoading={isLoading} onPress={async (e) => {
                            if (!betAmount || betAmount <= 0) {
                                toast.error("Please enter a valid amount.");
                                return;
                            }
                            if (betAmount > betLimit) {
                                toast.error("Bet amount exceeds the limit.");
                                return;
                            }

                            setIsLoading(true);
                            const res = await onSuccessCallback({
                                ...betData,
                                amount: betAmount,
                                message: betMessage

                            });
                            if (res) {
                                setIsOpen(false);
                                setIsLoading(false);
                                setBetAmount(0);
                                setBetMessage("");
                            } else {
                                setIsLoading(false);
                            }

                        }}>
                            {isLoading ? "Placing bet..." : "Place bet"}
                        </Button>
                    </ModalFooter>
                </Card>
            </ModalContent>
        </Modal>
    }, [onCloseCallback, onSuccessCallback, betData])

    return {
        ModalComponent: Component, openModal, closeModal
    }
}


export default useBetModal;