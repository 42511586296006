import { Button, Card, CardBody, Link } from "@nextui-org/react";
import { CardNice } from "../../components/small/CardNice";


export default function StakePage() {


    return (<div className="m-auto max-w-full">
        <h2 className="text-3xl font-bold m-3">Stake VSCN</h2>

        <CardNice>
            <CardBody>
                <div className="text- text-md">
                    {/* <h3 className="text-2xl text-center font-bold m-2">Ways to earn VSCN</h3>
                    <ul className="list-disc ml-8">
                        <li>Promoting VSCN coin and/or the battles platform.</li>
                        <li>Finding and reporting bugs and security issues.</li>
                        <li>Winning duels, lotteries and battles (duh).</li>
                        <li>Staking VSCN (feature planned in the near future).</li>
                    </ul>
                    <br /> */}

                    <h3 className="text-2xl text-center font-bold m-2">Earn VSCN by staking!</h3>
                    <h4 className="text-lg text-center font-bold m-2">Coming soon!</h4>
                    <br />
                    <p><b>Feature is not yet available and still in planning phase.</b></p>
                    <br />
                    <p>We are planning an exciting new staking feature for VSCN! Soon, you will be able to stake your VSCN and earn rewards from a portion of the fees collected by our platform. This initiative aims to promote and incentivize long-term holding of VSCN, enhancing the stability and growth of our community. Additionally, it will help promote our platform by encouraging more active participation and investment. Stay tuned for more updates on this upcoming opportunity!</p>
                    <br />


                </div>
            </CardBody>
        </CardNice>


    </ div>);
}