import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faXTwitter, faDiscord, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Button, Link } from '@nextui-org/react';


export default function Socials() {
    return (<div className="flex space-x-2 mt-12 justify-center">
        <Button as={Link} size="lg" isIconOnly href="https://t.me/vscn_bet" target="_blank">
            <FontAwesomeIcon className='text-xl max-w-6' icon={faTelegram} />
        </Button>
        <Button as={Link} size="lg"  isIconOnly href="https://twitter.com/vscn_bet" target="_blank">
            <FontAwesomeIcon className='text-xl max-w-6'  icon={faXTwitter} />
        </Button>
        <Button as={Link} size="lg"  isIconOnly href="https://dexscreener.com/solana/E2jThJotLtNpKcY3JpZ4GjaohLprY8A3P25S7URM7r4h" target="_blank" className='p-2' >
            <img className='text-xl max-w-6'  src="/img/dexscreener.svg" alt="DEX screener" />
        </Button>
        <Button as={Link} size="lg"  isIconOnly href="https://www.dextools.io/app/en/solana/pair-explorer/E2jThJotLtNpKcY3JpZ4GjaohLprY8A3P25S7URM7r4h" target="_blank" className='p-2' >
            <img className='text-xl max-w-6'  src="/img/dextools.png" alt="DEX tools" />
        </Button>
        <Button as={Link} size="lg"  isIconOnly href="https://raydium.io/swap/?outputCurrency=4GCJwmLfuk1F6s5Z6TxeLoBYQocaDh9yNLFxPokDHV7r" target="_blank" className='p-2' >
            <img className='text-xl max-w-6'  src="/img/raydium.png" alt="Raydium" />
        </Button>
        {/* <Button isIconOnly href="https://discord.com" target="_blank">
                <FontAwesomeIcon className='text-lg' icon={faDiscord} />
            </Button>
            <Button  isIconOnly  href="https://facebook.com" target="_blank">
                <FontAwesomeIcon className='text-lg' icon={faFacebook} />
            </Button>
            <Button  isIconOnly href="https://instagram.com" target="_blank">
                <FontAwesomeIcon className='text-lg' icon={faInstagram} />
            </Button> */}
    </div >);

}