
import { Button, Card, CardBody, Link, Spinner } from "@nextui-org/react";

import { useEffect, useState } from "react";
import Battle from "../components/battle/Battle";
import { redirect, useParams } from "react-router-dom";
import { socket } from "../services/socket";
import { useDispatch, useSelector } from "react-redux";
import { addLotteryAction, updateLotteryAction } from "../store/actions/reducerActions";
import Lottery from "../components/lottery/Lottery";
import { useLetsTalk } from "../hooks/useLetsTalk";
import { CardNice } from "../components/small/CardNice";




export default function LotteryPage() {
    const { id } = useParams();
    const lotteryId = Number.parseInt(id || '');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const { lotteries } = useSelector((state: any) => state.lotteriesData);
    const lottery = lotteries.find(x => x.id === lotteryId);

    const { doGet } = useLetsTalk();


    const loadLottery = async () => {
        if (loading) return;
        setLoading(true);
        setError(null);

        doGet(`lottery/get?id=${lotteryId}`).then((ret) => {
            // setCurrentBattle(battle);
            if (ret.ok) {
                dispatch(addLotteryAction(ret.data));
                document.title = `${lottery.name} - VersusCoin Battles - vscn.bet`;
                setError(null);

            } else {
                setError(ret.error || "Error loading lottery!");
            }
            setLoading(false);
            // console.log("Got lottery", lottery);
        }).catch((error) => {
            console.error(error);
            setLoading(false);
            setError(error);
        });

    }

    useEffect(() => {
        if (!lotteryId) redirect("/");
        loadLottery();
    }, [lotteryId]);

    useEffect(() => {
        if (lottery?.name) {
            document.title = `${lottery.name} - VersusCoin Battles - vscn.bet`;
        }

        function joinRoom() {
            socket.emit('join-room', `lottery-${lotteryId}`);
        }

        joinRoom();

        socket.io.on("reconnect", joinRoom);
        return () => {
            socket.emit('leave-room', `lottery-${lotteryId}`);
            socket.io.off("reconnect", joinRoom);
        };
    }, []);



    return (
        <>
            <Link href={"/info"} color="foreground" className="m-auto">
                <Card className="m-auto mb-4 bg-red-600">
                    <CardBody className="text-center ">
                        <p>Warning! Click and read before buying tickets!</p>
                    </CardBody>
                </Card>
            </Link>

            <CardNice finished={lottery?.finished}>
                {lottery && <Lottery lottery={lottery} />}
                {loading ?
                    <div className="m-4">
                        <Spinner size="lg" />
                        <div>Loading lottery...</div>
                    </div>
                    :
                    error ?
                        <div className="m-4">
                            <div>Error loading duel.</div>
                            <div className="text-xs text-danger">{error}</div>
                            <Button onClick={loadLottery}>Retry</Button>
                        </div>
                        : ''
                }

            </CardNice>

        </>
    )
}

// export default React.memo(BattlePage);