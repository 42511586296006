import { useCallback, useMemo, useState } from "react";
import { Button, Card, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { WalletBalance } from "../components/small/WalletBalance";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import anal from "../services/anal";
import useWalletBalance from "./useWalletBalance";


const useBuyTicketsModal = ({ onCloseCallback, onSuccessCallback, modalData: _modalData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ticketsData, setModalData] = useState(_modalData);

    const [ticketsAmount, setTicketsAmount] = useState(0);
    const [ticketsMessage, setTicketsMessage] = useState("");

    const { ticketsLimit } = useSelector((state: any) => state.betaData);

    const { refetchBalance } = useWalletBalance();

    // backdropo clickas sita iskviecia
    // ir visi kiti sita kviecia :>
    const closeModal = useCallback(() => {
        if (!isLoading) {
            setIsLoading(false);
            setTicketsAmount(0);
            setTicketsMessage("");
            onCloseCallback();
            setIsOpen(false);
        }
    }, [isLoading])

    const openModal = (ticketsData) => {
        // console.log("TICKETS DATA", ticketsData);
        refetchBalance();
        
        setModalData(ticketsData);
        setIsOpen(true);
        anal.event({ category: "lottery", action: "buy_tickets_modal_open", label: ticketsData?.name, value: ticketsData?.lotteryId });

    }

    const Component = useMemo(() => {
        return <Modal
            isOpen={isOpen}
            onOpenChange={closeModal}
            backdrop="blur"
            isDismissable={false}
            className="dark bg-default-100/50" classNames={{ closeButton: "z-10" }}
            scrollBehavior="outside"

        >
            <ModalContent>
                <Card className=" bg-default-100/50">
                    <ModalHeader className="flex flex-col gap-1">
                        <h2 className="text-center text-2xl">Buying tickets for <br />
                            <span style={{ fontFamily: "Barmo" }} >{ticketsData?.name}</span></h2>
                    </ModalHeader>
                    <ModalBody>
                        <div className="max-w-40 max-h-40 m-auto">
                            <img src={ticketsData?.img} alt={ticketsData?.name} />
                        </div>

                        <div className="mt-8 text-center w-full flex flex-col justify-center justify-items-center items-center">
                            <Input
                                label="Message (optional)"
                                type="text"
                                className="max-w-xs"
                                maxLength={128}
                                onChange={(e) => { setTicketsMessage?.(e.target.value) }}
                                value={ticketsMessage}
                            />
                            <br />

                            <h3 className=" text-small">Enter the amount</h3>
                            <Input
                                label="$VSCN amount"
                                type="number"
                                className="max-w-xs"
                                value={(ticketsAmount > 0) ? ticketsAmount.toString() : ''}
                                onChange={(e) => {
                                    setTicketsAmount(Number.parseFloat(e.target.value))
                                }}
                            />
                        </div>
                        <div className="text-center text-sm">
                            <WalletBalance />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="light" isDisabled={isLoading} onPress={() => {
                            closeModal();
                        }}>
                            Cancel
                        </Button>
                        <Button color="primary" isDisabled={isLoading || !ticketsAmount || ticketsAmount <= 0} isLoading={isLoading} onPress={async (e) => {
                            if (!ticketsAmount || ticketsAmount <= 0) {
                                toast.error("Please enter a valid amount.");
                                return;
                            }
                            if (ticketsAmount > ticketsLimit) {
                                toast.error("Tickets amount exceeds the limit.");
                                return;
                            }

                            setIsLoading(true);
                            const res = await onSuccessCallback({
                                ...ticketsData,
                                amount: ticketsAmount,
                                message: ticketsMessage

                            });
                            if (res) {
                                setIsOpen(false);
                                setIsLoading(false);
                                setTicketsAmount(0);
                                setTicketsMessage("");
                            } else {
                                setIsLoading(false);
                            }

                        }}>
                            {isLoading ? "Buying Tickets..." : "Buy Tickets"}
                        </Button>
                    </ModalFooter>
                </Card>
            </ModalContent>
        </Modal>
    }, [onCloseCallback, onSuccessCallback, ticketsData])

    return {
        ModalComponent: Component, openModal, closeModal
    }
}


export default useBuyTicketsModal;