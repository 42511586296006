
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Link, Spinner } from "@nextui-org/react";
import CurrentBattles from "../components/CurrentBattles";
import CurrentLotteries from "../components/CurrentLotteries";
import { socket } from "../services/socket";
import AppLogo from "../components/small/AppLogo";
import CurrentDuels from "../components/CurrentDuels";



export default function HomePage() {
    useEffect(() => {
        function joinRoom() {
            socket.emit('join-room', "home");
        }

        joinRoom();

        // socket.io.on("reconnect", () => {
        //     // console.log("HOME RECONNECT EVENT TRIGGERED");
        //     socket.emit('join-room', 'home')
        // });
        socket.io.on("reconnect", joinRoom);


        return () => {
            socket.emit('leave-room', "home");
            socket.io.off("reconnect", joinRoom);
        };
    }, []);

    return (<>
        <AppLogo className={"mb-4"} />

        <h2><Link href="/duels" color="foreground" className="text-3xl font-bold m-3">Duels</Link></h2>
        <CurrentDuels limit={3} />

        <h2 className="mt-8"><Link href="/battles" color="foreground" className="text-3xl font-bold m-3">Battles</Link></h2>
        <CurrentBattles limit={4} isPage={false} showFinished={false} />
        <br />
        <br />
        <CurrentLotteries className="mt-4" />
    </>
    );
}