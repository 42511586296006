
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { socket } from "../services/socket";
import CurrentBattles from "../components/CurrentBattles";




export default function BattlesPage() {
    useEffect(() => {
        function joinRoom() {
            socket.emit('join-room', "battles");
        }
        joinRoom();

        socket.io.on("reconnect", () => {
            // console.log("HOME RECONNECT EVENT TRIGGERED");
            socket.emit('join-room', 'battles')
        });

        return () => {
            socket.emit('leave-room', "battles");
            socket.io.off("reconnect", joinRoom);
        };
    }, []);

    return (<>
        <h2 className="text-3xl font-bold m-3">Battles</h2>
        <CurrentBattles limit={12} showFinished={true} isPage={true} />

    </>
    );
}