
import { useEffect, useRef, useState } from "react";
import { handImages } from "./hands";
import { Card, Input, Spinner } from "@nextui-org/react";
import { yellowGlow } from "../../../helpers/glows";
import { toast } from "react-toastify";
import sounds from "../../../services/sounds";
import { useLetsTalk } from "../../../hooks/useLetsTalk";
import DuelTurnTimer from "../DuelTurnTimer";
import { CardNice } from "../../small/CardNice";



export default function RPSCurrentHand({ action, endsAt, whichPlayer = 0 }: any) {
    const [selected, setSelected] = useState<any>(null);

    const [handMessage, setHandMessage] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    // const [soundReminderInterval, setSoundReminderInterval] = useState<any>(null);
    const [shouldShake, setShouldShake] = useState(false);

    const intervalRef = useRef<any>();

    const { doPost } = useLetsTalk();

    // const { doSignMessage } = useSignMessage();

    async function submitHand(hand) {
        setSelected(hand);
        setLoading(true);
        if (!(["r", "p", "s"].includes(hand))) {
            toast.error("Invalid hand!");
            return;
        };

        let data = {
            //walletId: string, //populatins doSignMessage
            duelId: action.duelId,
            rpsActionId: action.id,
            hand,
            message: handMessage
        };
        try {
            // let res = await doSignMessage({ url: "duel/rps/hand-action", data, successMessage: "Hand submitted!" });
            let res = await doPost("duel/rps/hand-action", data);
            if (res.ok) {
                setHandMessage(undefined);
            } else {

                toast.error(res.error || "Error submitting hand!");
            }

            // console.log("RES pas RPSCurrentHand", res);
        } catch (err) {
            console.error("Error in RPSCurrentHand", err);
            toast.error("Error submitting hand!");
        }
        setSelected(null);
        setLoading(false);

    }

    function remindPlayer() {
        // console.log("SOUND REMINDER", intervalRef.current, action.hand1, action.hand2, whichPlayer);
        sounds.playMakeYourMove();
        setShouldShake(true);
        const timer = setTimeout(() => setShouldShake(false), 1500);
        return timer;
    }

    useEffect(() => {
        // console.log("action changed", action.hand1, action.hand2, whichPlayer);
        if ((!action.hand1 && whichPlayer === 1) || (!action.hand2 && whichPlayer === 2)) {
            // console.log("setting sound reminder ");
            let totalDuration = (new Date(endsAt).getTime()) - (new Date().getTime());

            // Calculate the initial delay for the first reminder
            let initialDelay = totalDuration % (30 * 1000); // Remainder of totalDuration divided by 30 seconds
            if (initialDelay < 15 * 1000) { // If less than 15 seconds, adjust to the next 30-second interval
                initialDelay += 30 * 1000;
            }

            // let reminderIntervalTime = - 30 * 1000;
            let reminder = setInterval(() => {
                let timer = remindPlayer();
                return () => clearTimeout(timer);
            }, initialDelay - 15 * 1000);  //30*1000
            intervalRef.current = reminder;
            // console.log("set sound reminder", reminder);
        } else {
            // console.log("clearing sound reminder (faled if)", intervalRef.current);
            clearInterval(intervalRef.current);
        }
        return () => {
            // console.log("DESTRUCTOR, clearing sound reminder", intervalRef.current);
            clearInterval(intervalRef.current);
        }
    }, [action, whichPlayer]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        }
    }, []);

    return (<CardNice 
        key={action?.id}
        className={` mt-6 w-full max-w-xl  ${!loading && "scale-in-center"} ${shouldShake ? 'shake1500 blinkBg1500' : ''}`}
        // isBlurred
        // className={`m-auto mt-6 text-center w-full max-w-xl border-none bg-background/10 dark:bg-default-100/10  p-2 transition-all
        //     ${!loading && "scale-in-center"} ${shouldShake ? 'shake1500 blinkBg1500' : ''}`}
        // shadow="sm"
        style={{ transition: "height 3s ease-in-out" }}
    >

        {loading && <Spinner size="lg" color="white" label="Submitting..."></Spinner>}

        {(!loading && ((!action.hand1 && whichPlayer === 1) || (!action.hand2 && whichPlayer === 2))) ?
            <>
                <DuelTurnTimer endsAt={endsAt} />

                <div className=" w-full flex flex-col justify-center items-center">

                    <div className="text-lg mt-2">Pick your hand!</div>

                    <div className="m-4 flex flex-row justify-center gap-4 items-center">
                        {["r", "p", "s"].map((hand) => {
                            return (
                                <img key={hand}
                                    src={handImages[hand].img}
                                    alt={handImages[hand].name}
                                    className={`cursor-pointer drop-shadow-lg rpsHandSelect ${selected === hand ? 'rpsHandSelected' : ''} w-16 h-16`}
                                    style={{ 
                                        ...(!selected || selected === hand ? yellowGlow : {}),
                                        ...(whichPlayer == 2 ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" } : {})
                                     }}
                                    onClick={() => submitHand(hand)}
                                />
                            );
                        })}

                    </div>

                    <Input
                        label="Message (optional)"
                        type="text"
                        className="max-w-xs mt-4"
                        // color="primary"
                        classNames={{
                            input: [
                                "bg-transparent",
                                "text-black/90 dark:text-white/90",
                                "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                            ],
                            innerWrapper: "bg-transparent",
                            inputWrapper: [
                                "shadow-xl",
                                "bg-background/20",
                                "dark:bg-background/20",
                                "backdrop-blur-xl",
                                "backdrop-saturate-200",
                                "hover:bg-background/40",
                                "dark:hover:bg-background/40",
                                "group-data-[focus=true]:bg-background/60",
                                "dark:group-data-[focus=true]:bg-background/60",
                                "!cursor-text",
                            ]
                        }}
                        maxLength={128}
                        onChange={(e) => { setHandMessage?.(e.target.value) }}
                        value={handMessage}
                    />
                </div>
            </>
            :
            <div className="font-bold m-2">
                {whichPlayer === 0 ? "Waiting for players actions..." : "Waiting for opponent action..."}
            </div>
        }
    </CardNice>);
}