import { Button, Card, CardBody, Link } from "@nextui-org/react";
import { useSelector } from "react-redux";
import CIPSubmissionForm from "../../components/earn/CIPForm";
import CIPList from "../../components/earn/CIPList";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { CardNice } from "../../components/small/CardNice";

export default function CIPPage() {
    const { user } = useSelector((state: any) => state.userData);
    const cipListRef = useRef();


    return (<div className="m-auto max-w-full">
        {/* <h2 className="text-3xl font-bold m-3">Earn VSCN</h2> */}
        <h2 className="text-3xl font-bold m-3">Community Incentive Program</h2>

        <CardNice>
            <CardBody>
                <div className="text- text-md">
                    {/* <h3 className="text-2xl text-center font-bold m-2">Ways to earn VSCN</h3>
                    <ul className="list-disc ml-8">
                        <li>Promoting VSCN coin and/or the battles platform.</li>
                        <li>Finding and reporting bugs and security issues.</li>
                        <li>Winning duels, lotteries and battles (duh).</li>
                        <li>Staking VSCN (feature planned in the near future).</li>
                    </ul>
                    <br /> */}

                    {/* <h3 className="text-2xl text-center font-bold m-2">Community Incentive Program</h3> */}
                    <h4 className="text-lg text-center font-bold m-2">Promote VSCN or report bugs!</h4>
                    <p><b>Rules</b></p>
                    <ul className="list-disc ml-8">
                        <li>Provide a link to the post(s) and engagement statistics screenshots.</li>
                        <li>Do not break the rules of the platform you're promoting on.</li>
                        <li>Low quality or low effort submissions will not be rewarded.</li>
                        <li>Do not mislead or provide false information.</li>
                        <li>Submit posts/content created by You only.</li>
                        <li>Report bugs with detailed and reproducible steps.</li>
                    </ul>
                    <br />
                    <p>All submissions will be manually reviewed. Rewards will vary by the quality and effort of the content submitted and generated engagement or the severity of the bug and by current VSCN price. To increase the value of your submission, please provide proof of engagement (screenshots of the post statistics on the platform).</p>
                    <br />
                    {user && <>
                        <p>Increase rewards by adding your referal to links: <b>?r={user?.ref}</b></p>
                        <p>For example:
                            <br /><Link href="#" showAnchorIcon color="foreground"
                                onPress={() => {
                                    let url = `https://app.vscn.bet/?r=${user?.ref}`;
                                    let title = `Check out VersusCoin gaming platform!`;
                                    if (navigator.share) {
                                        navigator.share({ url, title });
                                    } else {
                                        navigator.clipboard.writeText(`${title} ${url}`);
                                    }
                                }}> https://app.vscn.bet/?r={user?.ref}</Link>
                            <br /><Link href="#" showAnchorIcon color="foreground"
                                onPress={() => {
                                    let url = `https://vscn.bet/?r=${user?.ref}`;
                                    let title = `Check out VersusCoin gaming platform!`;
                                    if (navigator.share) {
                                        navigator.share({ url, title });
                                    } else {
                                        navigator.clipboard.writeText(`${title} ${url}`);
                                    }
                                }}>https://vscn.bet/?r={user?.ref}</Link>
                        </p>
                    </>}
                    <br />
                    <Button as={Link} size="sm" target="_blank" href="/files/VSCN_COMMUNITY_MARKETING.zip" color="primary" variant="solid" showAnchorIcon>Download Media package</Button>


                    <CIPSubmissionForm onSubmit={() => {
                        (cipListRef.current as any)?.load();
                    }} />

                </div>
            </CardBody>
        </CardNice>

        {user && <CIPList ref={cipListRef} />}

    </ div >);
}