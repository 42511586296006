import { useCallback, useMemo, useState } from "react";
import { Button, Card, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Slider, Spinner, Tooltip } from "@nextui-org/react";
import { WalletBalance } from "../components/small/WalletBalance";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import anal from "../services/anal";
import EditableSlider from "../components/extensions/EditableSlider";
import useDoTransaction from "./useDoTransaction";
import { useLetsTalk } from "./useLetsTalk";
import InfoDuelTooltip from "../components/info-tooltip/InfoDuelTooltip";
import InfoTooltip from "../components/info-tooltip/InfoTooltip";
import useWalletBalance from "./useWalletBalance";

const useCreateDuelModal = ({ onCloseCallback, onSuccessCallback, modalData: _modalData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [duelData, setModalData] = useState(_modalData);

    const [createSettings, setCreateSettings] = useState<any>(null);

    const [duelAmount, setDuelAmount] = useState(0);
    const [duelName, setDuelName] = useState("");
    const [duelType, setDuelType] = useState("");
    const [duelScore, setDuelScore] = useState(0);
    const [duelTimeout, setDuelTimeout] = useState(0);
    const [duelTurnTimeout, setDuelTurnTimeout] = useState(0);


    const { duelLimit } = useSelector((state: any) => state.betaData);

    const { balanceVSCN, balanceSOL, publicKey } = useSelector((state: any) => state.walletBalance);
    const maintenance = useSelector((state: any) => state.maintenanceData);

    const { user } = useSelector((state: any) => state.userData);

    const { refetchBalance } = useWalletBalance();


    const { doTransaction } = useDoTransaction();

    const { doGet, doPost } = useLetsTalk();

    const closeModal = useCallback(() => {
        if (!isLoading) {
            setIsLoading(false);
            setDuelAmount(createSettings?.minAmount || 0);
            setDuelScore(Math.floor(createSettings?.maxWins / 3) || 0);
            setDuelTimeout(createSettings?.minTimeout || 0);
            setDuelTurnTimeout(createSettings?.minTurnTimeout || 0);
            setDuelName("");
            onCloseCallback();
            setIsOpen(false);
        }
    }, [isLoading])

    const openModal = (duelData) => {
        // console.log("DUEL DATA", duelData);
        if(!user){
            toast.error("Please connect your wallet to create a duel.");
            return;
        }
        refetchBalance();

        setModalData(duelData);
        setIsOpen(true);
        anal.event({ category: "duel", action: "create_duel_modal_open", label: duelData?.name, value: duelData?.duelId });

        doGet("duel/create-settings").then((data) => {
            // console.log("Got settings", data);
            setCreateSettings(data);
            setDuelAmount(data.minAmount);
            setDuelTimeout(data.minTimeout);
            //sitas iskeltas kai pasirenki tipa
            // setDuelScore(Math.floor(data.maxWins / 3));

        }).catch((error) => {
            console.error("Error obtaining settings", error)
            setCreateSettings(null);
            closeModal();
            toast.error("Error obtaining settings!");
        });


    }

    const createDuel = async (duelData): Promise<{ ok: boolean, error: string | undefined, duel: any }> => {
        return new Promise(async (resolve, reject) => {
            // console.log("sending to api", duelData);
            // console.log(createSettings.duelTypes);
            if (maintenance && maintenance?.starts < Date.now() && maintenance?.enabled) {
                toast.error("Transactions disabled because of the maintenance. Please wait until the maintenance is over.");
                return { ok: false, error: "Maintenance" };
            }

            let route = createSettings.duelTypes[duelData.type].route;
            if (!route) {
                // toast.error("Invalid duel type.");
                reject({ ok: false, error: "Invalid duel type." });
                return;
            }

            let data = await doPost(`duel/${route}/create`, duelData);
            if (data.ok) {
                resolve(data);
            } else {
                reject(data);
            }
          
        });
    }




    const Component = useMemo(() => {
        return <Modal
            isOpen={isOpen}
            onOpenChange={closeModal}
            backdrop="blur"
            isDismissable={false}
            className="dark bg-default-100/50" classNames={{ closeButton: "z-10" }}
            scrollBehavior="outside"

        >
            <ModalContent>
                <Card className=" bg-default-100/50">
                    <ModalHeader className="flex flex-col gap-1">
                        <h2 className="text-center text-2xl">Create a duel</h2>
                    </ModalHeader>
                    <ModalBody>
                        {/* <div className="max-w-40 max-h-40 m-auto">
                            <img src={duelData?.img} alt={duelData?.name} />
                        </div> */}

                        {!createSettings ? <Spinner size="lg" /> : (<>


                            <div className="mt-8 text-center w-full flex flex-col justify-center justify-items-center items-center">
                                <div className="flex flex-row items-center  w-full max-w-xs mb">
                                    <Select
                                        label="Select duel game"
                                        isRequired
                                        className="max-w-xs grow-1"
                                        // disabledKeys={["coin"]}
                                        onChange={(e) => {
                                            // console.log(e);
                                            setDuelType(e.target.value);
                                            let duelTypeSettings = createSettings?.duelTypes[e.target.value];
                                            // console.log(duelTypeSettings);
                                            if (duelTypeSettings) {
                                                // setDuelAmount(data.minAmount);
                                                if (duelTypeSettings.minWins && duelTypeSettings.maxWins) {
                                                    setDuelScore(duelTypeSettings.minWins == duelTypeSettings.maxWins ? duelTypeSettings.minWins : Math.floor(duelTypeSettings.maxWins / 3));
                                                }

                                                if (duelTypeSettings.minTurnTimeout && duelTypeSettings.maxTurnTimeout) {
                                                    setDuelTurnTimeout(Math.floor((duelTypeSettings.maxTurnTimeout - duelTypeSettings.minTurnTimeout) / 3));
                                                }
                                                // setDuelTimeout(data.minTimeout);
                                            }
                                        }}

                                    >
                                        {Object.keys(createSettings?.duelTypes).map((dtype, i) => (
                                            <SelectItem key={dtype} value={dtype}>
                                                {createSettings?.duelTypes[dtype]?.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                    {duelType && <InfoDuelTooltip type={duelType} classNames={{ trigger: "block ml-2" }} />}
                                </div>
                                <br />

                                {balanceVSCN < createSettings.minAmount ?
                                    <div className="text-red-500">You need at least {createSettings.minAmount} VSCN to create a duel.</div> :
                                    <>

                                        <Input
                                            label="Duel name"
                                            isRequired
                                            type="text"
                                            className="max-w-xs my-2"
                                            maxLength={createSettings?.maxRoomName || 128}
                                            onChange={(e) => { setDuelName(e.target.value) }}
                                            value={duelName}
                                        />
                                  


                                        <EditableSlider
                                            size="md"
                                            label="VSCN Amount"
                                            step={1000}
                                            minValue={createSettings.minAmount} //createSettings.minAmount
                                            maxValue={(createSettings.maxAmount < balanceVSCN ? createSettings.maxAmount : balanceVSCN)}
                                            defaultValue={createSettings.minAmount}
                                            className="max-w-xs"
                                            // value={value}
                                            // onChange={handleChange}
                                            onChange={(value) => { setDuelAmount(value) }}
                                            value={duelAmount}
                                        />


                                        <br />

                                        {duelType && <div className="w-full max-w-xs">
                                            {createSettings?.duelTypes[duelType].minWins < createSettings?.duelTypes[duelType].maxWins &&
                                                <EditableSlider
                                                    size="md"
                                                    label="Play until wins"
                                                    step={1}
                                                    minValue={createSettings?.duelTypes[duelType].minWins}
                                                    maxValue={createSettings?.duelTypes[duelType].maxWins}
                                                    defaultValue={Math.floor(createSettings?.duelTypes[duelType].minWins)}
                                                    className="max-w-xs"
                                                    disabled={createSettings?.duelTypes[duelType].minWins === createSettings?.duelTypes[duelType].maxWins}
                                                    // value={value}
                                                    // onChange={handleChange}
                                                    onChange={(value) => { setDuelScore(value) }}
                                                    value={duelScore}
                                                />
                                                // dont show slider if allowed to play until 1 win only
                                                // :
                                                // <div className="flex flex-row justify-between w-full ">
                                                //     <div className="">Play until wins</div>
                                                //     <input
                                                //         className="px-1 py-0.5 w-20 text-right text-small text-default-700 font-medium bg-default-100 outline-none transition-colors rounded-small border-medium border-transparent hover:border-primary focus:border-primary"
                                                //         type="text"
                                                //         aria-label="Temperature value"
                                                //         value={createSettings?.duelTypes[duelType].minWins}
                                                //         readOnly={true}
                                                //         disabled={true}
                                                //     />
                                                // </div>
                                            }
                                            <br />

                                            <EditableSlider
                                                size="md"
                                                label={<>Max wait for player (minutes) <InfoTooltip className="inline" classNames={{ trigger: 'inline text-md align-middle' }} infoText="Duel will be canceled if no opponent joins in specified time."></InfoTooltip></>}
                                                step={1}
                                                minValue={(createSettings.minTimeout / 60)}
                                                maxValue={(createSettings.maxTimeout / 60)}
                                                defaultValue={createSettings.minTimeout / 60}
                                                className="max-w-xs w-full"
                                                onChange={(value) => { setDuelTimeout(Number.parseInt(value) * 60) }}
                                                value={duelTimeout / 60}
                                            // value={value}
                                            // onChange={handleChange}
                                            />

                                            <br />
                                            {createSettings?.duelTypes[duelType].minTurnTimeout < createSettings?.duelTypes[duelType].maxTurnTimeout &&
                                                <EditableSlider
                                                    size="md"
                                                    label={<>Max turn time (seconds) <InfoTooltip className="inline" classNames={{ trigger: 'inline text-md align-middle' }} infoText="Player will lose if they fail to do their move in specified time."></InfoTooltip></>}
                                                    step={1}
                                                    minValue={createSettings?.duelTypes[duelType].minTurnTimeout}
                                                    maxValue={createSettings?.duelTypes[duelType].maxTurnTimeout}
                                                    defaultValue={Math.floor((createSettings?.duelTypes[duelType].maxTurnTimeout - createSettings?.duelTypes[duelType].minTurnTimeout) / 3)}
                                                    className="max-w-xs w-full"
                                                    onChange={(value) => { setDuelTurnTimeout(Number.parseInt(value)) }}
                                                    value={duelTurnTimeout}
                                                />
                                            }
                                        </div>
                                        }

                                        {/* <h3 className=" text-small">Enter the amount</h3>
                                <Input
                                    label="$VSCN amount"
                                    type="number"
                                    className="max-w-xs"
                                    value={(duelAmount > 0) ? duelAmount.toString() : ''}
                                    onChange={(e) => {
                                        setDuelAmount(Number.parseFloat(e.target.value))
                                    }}
                                /> */}

                                    </>}
                            </div>
                            <div className="text-center text-sm">
                                <WalletBalance />
                            </div>
                        </>)}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="light" isDisabled={isLoading} onPress={() => {
                            closeModal();
                        }}>
                            Cancel
                        </Button>
                        <Button color="primary" isDisabled={isLoading || !duelName || duelName.length < 2 || !duelAmount || duelAmount <= 0 || !duelType || duelType.length < 1 || !duelScore || !duelTimeout} isLoading={isLoading} onPress={async (e) => {
                            if (!duelAmount || duelAmount <= 0) {
                                toast.error("Please enter a valid amount.");
                                return;
                            }
                            if (duelAmount > duelLimit) {
                                toast.error("Duel amount exceeds the limit.");
                                return;
                            }

                            setIsLoading(true);

                            //issiust i serva kad sukurtu
                            createDuel({
                                walletId: publicKey,
                                type: duelType,
                                amount: duelAmount,
                                waitLength: duelTimeout,
                                waitLengthTurn: duelTurnTimeout,
                                scoreToWin: duelScore,
                                roomName: duelName
                            }).then(async (ret) => {
                                const res = await onSuccessCallback({
                                    duel: ret.duel
                                    //TODO: Add the rest of the data
                                    // ...duelData,
                                    // amount: duelAmount,
                                    // message: duelName
                                });
                                if (res) {
                                    setIsOpen(false);
                                    setIsLoading(false);
                                    setDuelAmount(0);
                                    setDuelName("");
                                } else {
                                    setIsLoading(false);
                                }
                            }).catch((error) => {
                                console.error("Error creating duel", error);
                                toast.error(error?.error || "Error creating duel.");
                                setIsLoading(false);
                            });

                            //reik issiust tx kad pajoinint
                            // console.log("got ret", ret);

                            // if (ret.ok && ret.duel) {

                            //     const res = await onSuccessCallback({
                            //         duel: ret.duel
                            //         //TODO: Add the rest of the data
                            //         // ...duelData,
                            //         // amount: duelAmount,
                            //         // message: duelName
                            //     });
                            //     if (res) {
                            //         setIsOpen(false);
                            //         setIsLoading(false);
                            //         setDuelAmount(0);
                            //         setDuelName("");
                            //     } else {
                            //         // setIsLoading(false);
                            //     }
                            // } else {
                            //     toast.error(ret.error);
                            // }
                            // setIsLoading(false);
                        }}>
                            {isLoading ? "Creating Duel..." : "Create Duel"}
                        </Button>
                    </ModalFooter>
                </Card>
            </ModalContent>
        </Modal >
    }, [onCloseCallback, onSuccessCallback, duelData])

    return {
        ModalComponent: Component, openModal, closeModal
    }
}

export default useCreateDuelModal;